import React from "react";
import classNames from "classnames";


const PrincipalTag = (props) => {
    const tag = props.tag;
    let ilustration = false; 
    let branding = false; 
    let multimedia = false;
    let c3d = false; 
    let sponsor = false;
    let uxui = false;
    let editorial = false;
    let photo = false;
    let textWhite = false;
    let textBlack = false;
    const imgWhite = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Ftag-white.png?alt=media&token=61ae81b6-fae6-432f-8880-2d13d659ab89';
    const imgBlack = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Filustraci%C3%B3n.png?alt=media&token=14f06246-2256-40b7-9c05-c6f07d7e61b3';
    const img3d = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2F3d.png?alt=media&token=779757e7-e45f-4eea-99e7-476d360b44c3';
    const imgBranding = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2Fbranding.png?alt=media&token=7b15e806-1a19-42bf-a8b1-fee0fb22d8c1';
    const imgEditorial = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2Feditorial.png?alt=media&token=ce43b3ce-8a24-4d94-8294-59a706f91e05';
    const imgMultimedia = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2Fmultimedia.png?alt=media&token=c9fa584c-f94d-4bda-acb9-e382e071df15';
    const imgPublicidad = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2Fpublicidad.png?alt=media&token=2964a174-e76a-4fdc-b46f-14866e073b15';
    const imgUi = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/tags%2Fui.png?alt=media&token=7dbd537a-fd0e-434e-8651-eaa3cbcd99c0';
    let imgSelect;
    console.log(tag);
    switch (tag.toLowerCase()) {
        case 'ilustracion':
            ilustration = true;
            textBlack = true;
            imgSelect = imgBlack;
        break;
        case 'ilustración':
            ilustration = true;
            textBlack = true;
            imgSelect = imgBlack;
        break;
        case 'branding':
            branding = true;
            textWhite = true;
            imgSelect = imgBranding;
        break;
        case 'multimedia':
            multimedia = true;
            textWhite = true;
            imgSelect = imgMultimedia;
        break;
        case '3d':
            c3d = true;
            textWhite = true;
            imgSelect = img3d;
        break;
        case 'publicidad':
            sponsor = true;
            textWhite = true;
            imgSelect = imgPublicidad;
        break;
        case 'ux/ui':
            uxui = true;
            textWhite = true;
            imgSelect = imgUi;
        break;
        case 'editorial':
            editorial = true;
            textBlack = true;
            imgSelect = imgEditorial;
        break;
        case 'fotografia':
            photo = true;
            textWhite = true;
            imgSelect = imgMultimedia;
        break;
    
        default:
            break;
    }

    if(tag !== ''){
        return(<>
            <div className="row">
                <div className={
                    classNames({
                        'col-2 col-md-3 col-lg-3 col-xl-2': true, 
                        'bg-color-ilustration': ilustration,
                        'bg-color-branding': branding,
                        'bg-color-multimedia': multimedia,
                        'bg-color-3d': c3d,
                        'bg-color-sponsor': sponsor,
                        'bg-color-ux-ui': uxui,
                        'bg-color-editorial': editorial,
                        'bg-color-photo': photo,
                        'text-white': textWhite,
                        'text-black': textBlack,
                    })
                }>
                    <img 
                        src={imgSelect} 
                        alt=""
                        className="img-fluid mx-0 mx-md-0 mx-lg-1 img-icon-button no-margin-padding" 
                    />
                </div>
                <div className={
                    classNames({
                        'col-10 col-md-9 col-lg-9 col-xl-10 p-1': true, 
                        'bg-color-ilustration': ilustration,
                        'bg-color-branding': branding,
                        'bg-color-multimedia': multimedia,
                        'bg-color-3d': c3d,
                        'bg-color-sponsor': sponsor,
                        'bg-color-ux-ui': uxui,
                        'bg-color-editorial': editorial,
                        'bg-color-photo': photo,
                        'text-white': textWhite,
                        'text-black': textBlack,
                    })
                }>
                    {tag}
                </div>
            </div>
        </>)
    }
    return(<></>)
};


export default PrincipalTag;