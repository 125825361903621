import React from "react";

const SocialIcons = (props) => {
    const student = props.student;

    const selectIcon = (icon) =>{
        let newIcon;
        switch (icon) {
            case 'instagram':
                newIcon = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fig-icon-dark.png?alt=media&token=8e218320-97ac-4f37-b53a-174b76e9a9e5';
            break;
            case "behance":
                newIcon = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fbe-icon-dark.png?alt=media&token=3f998476-6ebe-4375-9bf6-5c4e57764b62';
            break;
            case "email":
                newIcon = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fic_outline-email.png?alt=media&token=22d8c53a-0d28-4e16-864c-fe5d2ad5ce8b';
            break;
            default:
                newIcon = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fin-icon-dark.png?alt=media&token=2e043ef1-86de-48be-a2da-7ff0a909cb4d';
                break;
        }
        return newIcon;
    };
    
    function StudentIf(){
        let list = {};
        const buttons = student.buttons;

        if(buttons !== undefined){
            if(buttons.instagram !== ''){
                list = {instagram: buttons.instagram, ...list}
            }
            if(buttons.behance !== ''){
                list = {behance: buttons.behance, ...list}
            }
            if(buttons.linkedin !== ''){
                list = {linkedin: buttons.linkedin, ...list}
            }
            if(buttons.email !== ''){
                list = {email: buttons.email, ...list}
            }
            const namesSocial = [];
            const linksSocial = [];
            Object.values(list).map(key => {
                linksSocial.push(key);
            });
            Object.keys(list).map(key => {
                namesSocial.push(key);
            });

            const finalList = namesSocial.map((item, key)=>{
                // console.log(namesSocial)
                return(
                    <div className="col-2 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                        <a href={namesSocial[key] === 'email' ? 'mailto:'+linksSocial[key] : linksSocial[key]} target="_blank">
                            <img 
                                src={selectIcon(namesSocial[key])} 
                                alt="" 
                                className="img-icon-button no-margin-padding img-fluid mt-1"
                            />
                        </a>
                    </div>
                );
            });

            return(finalList);

        }

        return (
            <div></div>
        )
    }

    return(
        <>
            <StudentIf />
        </>
    );
};

export default SocialIcons;