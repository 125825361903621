import React, {useState, useEffect} from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


const Collage = (props) => {
    const student = props.student;
    const imgDefault = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fcollage-default.jpg?alt=media&token=315dad6b-d1f5-498b-badb-bf38a6fcbad1';
    
    if(student.linkCollage === undefined){
        student.linkCollage = imgDefault;
    }
    const photo = student.linkCollage;


    if(photo !== undefined){
        return(
            <div className="col-12 col-sm-12 col-md-12 my-md-5 col-lg-6 col-xl-5 mb-md-5 mb-lg-5 my-lg-0 my-xl-0">
                <img 
                    src={photo}
                    alt=""
                    className="img-detail no-border-padding"    
                />
            </div>
        );
    }

    return(<></>); 
};


export default Collage;