import React, {useState, useEffect} from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


const ProfileImg = (props) => {
    const student = props.student;
    const imgDefault = 'https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fuser-default.png?alt=media&token=9b12c869-dce3-429b-be12-ca5c026b2395';
    if(student.linkPerson === undefined){
        student.linkPerson = imgDefault;
    }
    const photo = student.linkPerson;

    if(photo !== undefined){
        return(
            <div className="col-12 no-margin-padding">
                <div className="row">
                    <img 
                        src={photo} 
                        alt=""
                        className="img-fluid no-margin-padding"    
                    />
                </div>
            </div>
        );
    }

    return(<></>); 
};


export default ProfileImg;