import React, {useState, useEffect} from "react";
import SecondHeader from "../../components/second-header";
import Footer from '../../components/footer';
import Seo from "../../components/seo"
import '../../css/index.css';
// components
import ProfileImg from '../../components/Details/ProfileImg'
import DownloadCV from '../../components/Details/DownloadCV' 
import PrincipalTag from "../../components/Details/PrincipalTag";
import Collage from '../../components/Details/Collage'
import SocialIcons from '../../components/SocialIcons';
import CopyInput from "../../components/Details/CopyInput";
// conect with firestore
import { store } from '../../components/Firebase/firebase-config';


const DetallePortafolio = ({id}) => {

    const [student, setStudent] = useState('');
    const [imgDownload, setImgDownload] = useState('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow--right-white.png?alt=media&token=99b73ea9-6b33-4b04-af20-aa70e64912af');

    const getStudentForId = async () => {
        const dbRef = store.collection('students').doc(id);
        const doc = await dbRef.get();
        const student = doc.data();
        setStudent({ ...student, id: doc.id});
    };

    useEffect(()=>{
        getStudentForId();
    },[0])

    function Profile(){
        if(student.id !== undefined){
            return(
                <ProfileImg student={student}/>
            );
        }
        return(<></>);
    };

    function CollageLoading(){
        if(student.id !== undefined){
            return(
                <Collage student={student} />
            );
        }
        return(<></>);
    };

    function DownloadCVLoading(){
        if(student.id !== undefined){
            return(
                <DownloadCV student={student} />
            );
        }
        return(<></>);
    }

    function PrincipalTagLoading(){
        if(student !== ''){
            return(<PrincipalTag tag={student.tags[0]}/>)
        }
        return(<></>);
    }

    function CopyInputLoading(){
        if(student !== ''){
            return(<CopyInput student={student}/>)
        }
        return(<></>);
    }

    function Tags(){
        if(student !== ''){
            const list = (student.tags).map((item)=>{
                if(item.toLowerCase() === 'branding'){
                    return(
                        <button className="badge bg-color-branding bg-color-base text-white border-radius-bagde px-3  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'ilustracion' || item.toLowerCase() === 'ilustración'){
                    return(
                        <button className="badge bg-color-ilustration bg-color-base text-black border-radius-bagde px-3  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'multimedia'){
                    return(
                        <button className="badge bg-color-multimedia bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === '3d'){
                    return(
                        <button className="badge bg-color-3d bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'publicidad'){
                    return(
                        <button className="badge bg-color-sponsor bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'ux/ui'){
                    return(
                        <button className="badge bg-color-ux-ui bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'uxui'){
                    return(
                        <button className="badge bg-color-ux-ui bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'editorial'){
                    return(
                        <button className="badge bg-color-editorial bg-color-base text-black border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                if(item.toLowerCase() === 'fotografia'){
                    return(
                        <button className="badge bg-color-photo bg-color-base text-white border-radius-bagde px-3 py-1  border-transparent margin-right-1">{item}</button>
                    )
                }
                return(<></>)
            })
            return(list);
        }
        return(<></>);
    };

    function Portfolio(props){
        const link = props.link;
        if(link === ''){
            return(
                <a 
                    href='#'
                    className="btn btn-block btn-primary text-start btn-see-portafolio font-family-archivo-semibold">
                    Proximamente
                    <span>
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow--right-white.png?alt=media&token=99b73ea9-6b33-4b04-af20-aa70e64912af" 
                            alt="" 
                            className="img-go-back-button img-fluid no-margin-padding"
                        />
                    </span>
                </a>
            );
        }
        return(
            <a 
                href={link}
                target="_blank"
                className="btn btn-block btn-primary text-start btn-see-portafolio font-family-archivo-semibold"
                onMouseEnter={()=>{
                    setImgDownload('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow-dark.png?alt=media&token=70635403-dd7c-4569-ac51-9b75253c1005');
                }}
                onMouseLeave={()=>{
                    setImgDownload('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow--right-white.png?alt=media&token=99b73ea9-6b33-4b04-af20-aa70e64912af');
                }}
            >
                <div className="row">
                    <div className="col-10">
                        Ver Portafolio
                    </div>
                    <div className="col-2">
                        <span className="text-right">
                            <img 
                                src={imgDownload} 
                                alt="" 
                                className="img-go-back-button img-fluid no-margin-padding"
                            />
                        </span>
                    </div>
                </div>
            </a>
        );
    }

    return(
        <>
            <SecondHeader />
            <div className="container-fluid detail-background">
                <div className="container ">
                    <div className="row border-bottom">
                        <div className="col-12">
                            <a 
                                href="/portafolios"
                                className="text-decoration-none text-dark-special go-back"
                            >
                                <span>
                                    <img 
                                        src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Farrow-left.png?alt=media&token=04793fc4-222a-402e-a17f-7d310e6c16c8"
                                        alt=""
                                        className="img-go-back-button mt-4 mx-md-3 mx-lg-3"
                                    />
                                </span>
                                Volver
                            </a>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7 mb-5 mb-lg-0 mb-md-0">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="container">
                                        <div className="row">
                                            <Profile />
                                            <div className="col-12 no-margin-padding">
                                                    <PrincipalTagLoading/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                    <div className="row justify d-flex-content-sm-center">
                                        <div className="col-12 mx-md-5 mx-lg-5 mt-3 font-family-archivo">
                                            <Tags />
                                        </div>
                                        <div className="col-12 mx-md-5 mx-lg-5">
                                            <p className=" name-portafolio font-family-archivo"><b>{student.name} {student.lastname}</b></p>
                                        </div>
                                        <div className="col-10 mx-md-5 mx-lg-5">
                                            <p className="text-detail-portafolio font-family-archivo">
                                                {student.description !== '' ? student.description : `Hola soy ${student.name} ${student.lastname} un gusto saludarte contactame`}
                                            </p>
                                        </div>
                                        <div className="col-12 mx-md-5 mx-lg-5">
                                            <p className="font-family-archivo"><b>Sígueme en:</b></p>
                                        </div>
                                        <div className="row col-separate">
                                            <SocialIcons student={student}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 no-margin-padding">
                                    <div className="container">
                                        <div className="row mt-3">
                                            <Portfolio link={student.link}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 no-margin-padding">
                                    <div className="container">
                                        <DownloadCVLoading />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-11 col-lg-11 no-margin-padding">
                                    <div className="container">
                                        <CopyInputLoading />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CollageLoading />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export const Head = () => <Seo title="Detalle portafolio" />

export default DetallePortafolio;