import React, {useRef, useState} from "react";
import classNames from 'classnames';


const CopyInput = (props) => {
    const student = props.student;
    const txtCopy = useRef();
    const [statusCopy, setStatusCopy] = useState(false);

    const copyText = () => {
        const changue = !statusCopy;
        txtCopy.current.select();
        document.execCommand("copy");
        setStatusCopy(changue);
    };

    return(<> 
            <div className="row mt-3">
                <div className= {
                    classNames({
                        'input-group': true, 
                        'input-search': true, 
                        'no-margin-padding': true, 
                        'border-color-copy-selected': statusCopy,
                    })
                }>
                    <span className = {
                        classNames({
                            'input-group-text': true,
                            'no-border-rigth': true, 
                            'bg-white input-search': true,
                            'input-copy-selected': statusCopy
                        })
                    }>
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Flink.png?alt=media&token=1b42433b-e942-4c32-990f-53c6cee4517a" 
                            alt=""
                            className="icons-input no-margin-padding img-input"
                        />
                    </span>
                    <input 
                        type="text" 
                        ref={txtCopy}
                        id="txtCopy"
                        className = {
                            classNames({
                                'form-control': true, 
                                'font-family-archivo': true,
                                'no-border-left': true,
                                'no-border-rigth': true, 
                                'text-black':true, 
                                'input-copy-selected': statusCopy,
                            }) 
                        }
                        placeholder="Busca por nombre"
                        value={student.link}
                    />
                    <span className = {
                        classNames({
                            'input-group-text': true, 
                            'no-border-left': true, 
                            'bg-white': true,
                            'input-copy-selected': statusCopy
                        })
                    }>
                        <button 
                            onClick={() =>{
                                copyText();
                            }}
                            className = {
                                classNames({
                                    'btn': true, 
                                    'btn-sm': true, 
                                    'btn-download-download': true,
                                    'input-copy-selected': statusCopy,
                                    'color-copy-selected': statusCopy,
                                })
                            }
                        >{ statusCopy ? 'Copiado' : 'Copiar'}</button>
                    </span>
                </div>
            </div>
    </>)

};

export default CopyInput;