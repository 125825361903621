import React, {useState, useEffect} from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


const DownloadCV = (props) => {
    const student = props.student;
    const [imgCV, setImgCV] = useState('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fdownload.png?alt=media&token=dbda24f1-0020-437c-8bbc-a86feb4c864a');
    
    if(student.linkCV === undefined){
        student.linkCV = '';
    }
    const link = student.linkCV;
    

    if(link !== undefined){
        return(
            <div className="row mt-3">
                <a 
                    href={link}
                    target="_blank"
                    className="btn btn-block btn-download-cv text-start font-family-archivo-semibold"
                    onMouseEnter={()=>{
                        setImgCV('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fdownload-white.png?alt=media&token=9e3b811f-e438-425a-9eaf-9ca536662619');
                    }}
                    onMouseLeave={()=>{
                        setImgCV('https://firebasestorage.googleapis.com/v0/b/mad-udb.appspot.com/o/img%2Fdownload.png?alt=media&token=dbda24f1-0020-437c-8bbc-a86feb4c864a');
                    }}
                
                >
                    
                    <div className="row">
                        <div className="col-10">
                            Descargar CV
                        </div>
                        <div className="col-2">
                            <span className="text-right">
                                <img 
                                    src={imgCV} 
                                    alt="" 
                                    className="img-go-back-button img-fluid no-margin-padding"
                                />
                            </span>
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    return(<></>); 
};


export default DownloadCV;